<template>
  <div class="d-flex">
    <span :class="`subtitle-2 align-self-start ${auxTextColor}`">R$</span>
    <span class="price mx-1">{{ price }}</span>
    <span :class="`caption align-self-end ${auxTextColor}`">/mês</span>
  </div>
</template>

<script>
export default {
  name: "PriceText",
  props: {
    price: {
      type: String,
      required: true,
    },
    auxTextColor: {
      type: [String, Boolean],
      default: "grey--text text--darken-2",
    },
  },
};
</script>

<style scoped>
.price {
  font-weight: 700;
}
</style>
